<template>
  <div class="grid admintext-demo">
    <Toolbar class="toolbar_top">
      <template v-slot:start>
        <button class="p-link" id="btn_back" @click="backToCustomer">
          <i class="pi pi-angle-left"></i>
        </button>
        <div class="my-2">
          <h3>{{ t('siteManagement') }} (Client : {{ customer.name }})</h3>
        </div>
      </template>
    </Toolbar>
    <div class="col-12">
      <div class="card">
        <Toolbar class="block_add_btn">
          <template v-slot:start>
            <div class="my-2">
              <Button :label="t('addASite')" icon="pi pi-plus" class="add-text p-button-success mr-2"
                      @click="openNewSite"/>
            </div>
            <div class="my-2">
              <Button :label="t('importSites')" icon="pi pi-plus" class="add-text p-button-success mr-2"
                      @click="importSites"/>
            </div>
          </template>
          <template v-slot:end>
            <Button type="button" icon="pi pi-plus" :label="t('developAll')" @click="expandAll"
                    class="add-text mr-2 inline-block"/>
            <Button type="button" icon="pi pi-minus" :label="t('cutThroughAll')" @click="collapseAll"
                    class="add-text p-button-help"/>
          </template>
        </Toolbar>
        <TreeTable :value="sites" :expandedKeys="expandedKeys" :paginator="true" :rows="30" :loading="loadingSites"
                   :totalRecords="totalItemsSite" @page="onPageSite($event)" responsiveLayout="scroll">
          <Column field="id" header="Id" :expander="true" style="width:10rem"></Column>
          <Column field="code" header="Code" style="width:10rem"></Column>
          <Column field="name" :header="t('wording')" style="width:10rem"></Column>
          <Column field="type" header="Type" style="width:10rem"></Column>
          <Column field="logo" header="Logo" style="width:30rem"></Column>
          <Column :header="t('update')" style="width:10rem;text-align: center;">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" class="button-table mr-2" @click="modalEditSite(slotProps.node)"
                      v-tooltip.top="{value:t('edit'), class: 'editBtn'}"/>
              <Button icon="pi pi-fw pi-times" class="btn_remove button-table mr-2"
                      @click="deleteSite(slotProps.node?.id)"
                      v-tooltip.top="{value:t('delete'), class: 'editBtn'}"
                      v-if="slotProps.node.entity_type != 'customer'"/>
            </template>
          </Column>
          <Column :header="t('copyBase')" style="width:10rem;text-align: center;">
            <template #body="slotProps">
              <Button icon="pi pi-copy" class="button-table mr-2"
                      @click="modalCopyBaseSite(slotProps.node)"
                      v-tooltip.top="{value:t('edit'), class: 'editBtn'}"
                      v-if="slotProps.node.entity_type == 'site'"/>
            </template>
          </Column>
          <template #empty>
            {{ t('NoDataToDisplay') }}
          </template>
        </TreeTable>
        <Dialog v-model:visible="addSiteDialog" :style="{width: '900px'}" :header="t('addASite')" :modal="true"
                class="modal_general p-fluid">
          <TabView class="tabs_texte_admin tabview-custom">
            <TabPanel>
              <template #header>
                <span>Structure</span>
              </template>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="titre">Code* :</label>
                  <InputText id="name" v-model="site.code" required="true" autofocus
                             :class="{'p-invalid': submitted && !site.code}"/>
                  <small class="p-invalid" v-if="submitted && !site.code">Code is required.</small>
                </div>
                <div class="field-control col">
                  <label for="titre">{{ t('wording') }}* :</label>
                  <InputText id="name" v-model="site.name" required="true" autofocus
                             :class="{'p-invalid': submitted && !site.name}"/>
                  <small class="p-invalid" v-if="submitted && !site.name">Name is required.</small>
                </div>
              </div>

              <div class="grid formgrid">
                <div class="field-control col">
                  <label for="theme">Type</label>
                  <Dropdown v-model="typeValue" :options="typeValues" optionLabel="name"
                            :placeholder="t('select')"/>
                </div>

                <div class="field col">
                  <label for="titre">{{ t('parentSite') }} :</label>
                  <TreeSelect v-model="selectedsiteParent" :options="sitesParents"
                              selectionMode="single"
                              placeholder="Select Item"/>
                </div>
              </div>

              <div class="formgrid grid">
                <div class="field col">
                  <label for="Priorite">{{ t('year') }} </label>
                  <InputNumber id="name" v-model.trim="site.year" required="true" autofocus/>

                </div>
                <div class="field col">
                </div>

              </div>

            </TabPanel>
            <TabPanel>
              <template #header>
                <span>{{ t('informationsOnTheSite') }}</span>
              </template>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="titre">{{ t('address') }} :</label>
                  <InputText id="name" v-model.trim="site.address" required="true" autofocus
                  />
                </div>
                <div class="field-control col">
                  <label for="statut">{{ t('additionalAddress') }} :</label>
                  <InputText id="name" v-model.trim="site.additional_address" required="true"
                             autofocus
                  />
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="Priorite">{{ t('zipCode') }} : </label>
                  <InputText id="name" v-model.trim="site.zipcode" required="true" autofocus
                  />
                </div>
                <div class="field-control col">
                  <label for="statut">{{ t('city') }} :</label>
                  <InputText id="name" v-model.trim="site.city" required="true" autofocus
                  />
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="Priorite">{{ t('country') }} : </label>
                  <!--                  <InputText id="name" v-model.trim="site.address" required="true" autofocus-->
                  <!--                             />-->

                  <Dropdown v-model="valuecountry" :options="countries" optionLabel="name"
                            required="true"
                            autofocus/>
                </div>
              </div>
              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="Priorite">{{ t('phoneNumber') }} : </label>
                  <InputText id="name" v-model.trim="site.phone" required="true" autofocus
                  />
                </div>
                <div class="field-control col">
                  <label for="statut">Fax :</label>
                  <InputText id="name" v-model.trim="site.fax" required="true" autofocus
                  />
                </div>
              </div>

              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="Priorite">Code NAF : </label>
                  <InputText id="name" v-model.trim="site.naf" required="true" autofocus
                  />
                </div>
                <div class="field-control col">
                  <label for="statut">{{ t('group') }} :</label>
                  <InputText id="name" v-model.trim="site.groupe" required="true" autofocus
                  />
                </div>
              </div>

              <div class="formgrid grid">
                <div class="field-control col">
                  <label for="Priorite">Code ADR : </label>
                  <InputText id="name" v-model.trim="site.adr" required="true" autofocus
                  />
                </div>
                <div class="field-control col">
                  <label for="statut">{{ t('LambertCoordinates') }} :</label>
                  <InputText id="name" v-model.trim="site.coordinates_lambert" required="true"
                             autofocus
                  />
                </div>
              </div>
            </TabPanel>
            <TabPanel>
              <template #header>
                <span>{{ t('parameters') }}</span>
              </template>
              <div class="formgrid grid">
                <div class="field-control col">
                  <DataTable ref="dt" :value="countries" v-model:selection="selectedCountries"
                             dataKey="id"
                             :paginator="true" :rows="5" :filters="filters"

                             :rowsPerPageOptions="[5,10,25]"

                             responsiveLayout="scroll">
                    <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                    <Column>
                      <template #body="slotProps">
                        {{ slotProps.data.name }}
                      </template>
                    </Column>
                    <Column>
                    </Column>
                  </DataTable>
                </div>
                <div class="field-control col">
                  <DataTable ref="dt" :value="categoriesTheme"
                             v-model:selection="selectedCategoriesTheme" dataKey="id"
                             :paginator="true" :rows="5" :filters="filters"

                             :rowsPerPageOptions="[5,10,25]"

                             responsiveLayout="scroll">
                    <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                    <Column>
                      <template #body="slotProps">
                        {{ slotProps.data.name }}
                      </template>
                    </Column>
                  </DataTable>
                </div>
              </div>
            </TabPanel>
            <TabPanel :disabled="!isSite">
              <template #header>
                <span>{{ t('listOfTexts') }}</span>
              </template>

              <!--              <div class="my-2">-->
              <!--                <Button label="Affecter un texte" icon="pi pi-check" class="p-button-text next_btn"-->
              <!--                        @click="assignDocumentsModal()"/>-->
              <!--              </div>-->

              <div class="table-header-container flex_btn_attribute">


                <Button :label="t('desaffecterDocument')" icon="pi pi-times"
                        class="p-button-text event_remove" @click="desaffectDocument"/>
                <Button label="Affecter un texte" icon="pi pi-check" class="p-button-text next_btn"
                        @click="assignDocumentsModal()"/>
              </div>

              <DataTable :value="assignDocuments"
                         :paginator="true" class="p-datatable-gridlines table-filter-texte"
                         :rows="5" :totalRecords="nbElmentsAssignDocuments" dataKey="id" :rowHover="true"
                         v-model:filters="filtersAssignDocument" filterDisplay="menu"
                         responsiveLayout="scroll"
                         :globalFilterFields="['title','dated_at','last_updated_at','theme','domain_h','sub_domain_h', 'is_applicable']"
                         :loading="loadingAssignDocuments"
                         v-model:selection="selectedDocument"
                         @filter="filterDataTableAssignDocuments($event)"
              >

                <template #empty>
                  {{ t('NoDataToDisplay') }}
                </template>
                <template #loading>
                  {{ t('LoadingDataPleaseWait') }}
                </template>


                <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
                <Column field="title" :header="t('text')" style="width:10rem"
                        :showFilterMatchModes="false">
                  <template #body="{data}">
                    <div class="tooltip"
                         v-tooltip.right="'<p><strong> '+  t('text') +' : </strong>'+data.document.title+'</p><p><strong>Description : </strong>'+
                      data.document.description+'</p><p><strong>'+t('fieldApplication') +' : </strong>'+data.document.application_fields+'</p>'">
                      <p>{{ data.document.title }}</p>
                      <p v-html="data.document.description"></p>
                    </div>
                    <!--                    <p v-show="false">{{ data.document.title }}</p>-->
                    <!--                    <p v-show="false">{{  data.description }}</p>-->

                  </template>


                  <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                               placeholder="Titre"/>
                  </template>
                </Column>
                <Column :header="t('applicability')" filterField="is_applicable" :showFilterMatchModes="false"
                        style="min-width:8rem">
                  <template #body="{data}">
                    <span> {{ data.is_applicable }} </span>
                  </template>

                  <template #filter="{filterModel}">
                    <Dropdown v-model="filterModel.value" :options="applicabilities" placeholder=" Any "
                              class="p-column-filter" :showClear="true">
                      <template #value="slotProps">
                                          <span :class="'customer-badge status-' + slotProps.value"
                                                v-if="slotProps.value">{{ slotProps.value }}</span>
                        <span v-else>{{ t('select') }}</span>
                      </template>
                      <template #option="slotProps">
                                              <span :class="'customer-badge status-' + slotProps.option">{{
                                                  slotProps.option
                                                }}</span>
                      </template>
                    </Dropdown>
                  </template>

                </Column>
                <Column :header="t('dateOfText')" style="min-width:8rem" filterField="dated_at"
                        dataType="date"
                        :showFilterMatchModes="false">
                  <template #body="{data}">

                    {{ formatDate(data.document.dated_at) }}
                  </template>
                  <template #filter="{filterModel}">
                    <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy"
                              placeholder="dd/mm/yyyy"/>
                  </template>
                </Column>


                <Column :header="t('updatedAt')" style="min-width:9rem" filterField="last_updated_at"
                        dataType="date"
                        :showFilterMatchModes="false">
                  <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle"
                      class="image-text">{{ formatDate(data.document.last_updated_at) }}</span>
                  </template>
                  <template #filter="{filterModel}">
                    <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy"
                              placeholder="dd/mm/yyyy"/>
                  </template>
                </Column>

                <Column :header="t('theme')" filterField="theme" style="min-width:8rem"
                        :showFilterMatchModes="false">
                  <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle" v-if="data.document.theme"
                      class="image-text">{{ data.document.theme.name }}</span>
                  </template>
                  <template #filter="{filterModel}">
                    <Dropdown v-model="filterModel.value" :options="categoriesThemeDocAssFilter"
                              placeholder=" Any "
                              class="p-column-filter" :showClear="true">
                      <template #value="slotProps">
                    <span :class="'customer-badge status-' + slotProps.value"
                          v-if="slotProps.value">{{ slotProps.value }}</span>
                        <span v-else>{{ t('select') }}</span>
                      </template>
                      <template #option="slotProps">
                        <span :class="'customer-badge status-' + slotProps.option">{{
                            slotProps.option
                          }}</span>
                      </template>
                    </Dropdown>
                  </template>
                </Column>
                <Column :header="t('domain')" filterField="domain_h" :showFilterMatchModes="false"
                        :filterMenuStyle="{'width':'14rem'}" style="min-width:8rem">
                  <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle" v-if="data.document.domain"
                      class="image-text">{{ data.document.domain.name }}</span>
                  </template>
                  <template #filter="{filterModel}">
                    <Dropdown v-model="filterModel.value" :options="categoriesDomainAssfilter"
                              placeholder=" Any "
                              class="p-column-filter" :showClear="true">
                      <template #value="slotProps">
                    <span :class="'customer-badge status-' + slotProps.value"
                          v-if="slotProps.value">{{ slotProps.value }}</span>
                        <span v-else>{{ t('select') }}</span>
                      </template>
                      <template #option="slotProps">
                        <span :class="'customer-badge status-' + slotProps.option">{{
                            slotProps.option
                          }}</span>
                      </template>
                    </Dropdown>
                  </template>
                </Column>
                <Column :header="t('subDomain')" filterField="sub_domain_h" style="min-width:8rem"
                        :showFilterMatchModes="false">
                  <template #body="{data}">
                    <span v-if="data.document.sub_domain">{{ data.document.sub_domain.name }}</span>
                  </template>
                  <template #filter="{filterModel}">
                    <Dropdown v-model="filterModel.value" :options="categoriesSubDomainAssFilter"
                              placeholder=" Any "
                              class="p-column-filter" :showClear="true">
                      <template #value="slotProps">
                    <span :class="'customer-badge status-' + slotProps.value"
                          v-if="slotProps.value">{{ slotProps.value }}</span>
                        <span v-else>{{ t('select') }}</span>
                      </template>
                      <template #option="slotProps">
                        <span :class="'customer-badge status-' + slotProps.option">{{
                            slotProps.option
                          }}</span>
                      </template>
                    </Dropdown>
                  </template>
                </Column>


              </DataTable>


            </TabPanel>
          </TabView>
          <template #footer>
            <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                    @click="hideDialog"/>


            <Button v-if="site.id" :label="t('modify')" icon="pi pi-check" class="p-button-text next_btn"
                    @click="editSite()"/>
            <Button v-else :label="t('next')" icon="pi pi-check" class="p-button-text next_btn"
                    @click="saveSite"/>

          </template>
        </Dialog>

        <Dialog v-model:visible="assignDocumentsDialog" :style="{width: '900px'}" :header="t('assignDocuments')"
                :modal="true"
                class="modal_general p-fluid">

          <template #header>
            <span>{{ t('listOfTexts') }}</span>
          </template>
          <div class="grid pb-4">
            <div class="col-6">
              <Dropdown v-model="valueStatusAffect" :options="valuesStatusAffect" optionLabel="label"
              />
            </div>

            <div class="col-6">
              {{ nbDocumentsSelected }} {{ t('selectedDocuments') }}
            </div>
          </div>
          <DataTable :value="documents['hydra:member']" :lazy="true"
                     :totalRecords="documents['hydra:totalItems']"
                     @page="onPageDocuments($event)" :paginator="true"
                     class="p-datatable-gridlines table-filter-texte mt-3 ax-background"
                     :rows="30" dataKey="id" :rowHover="true"
                     v-model:filters="filters1" filterDisplay="menu" :loading="loadingDocuments"
                     :filters="filters1"
                     responsiveLayout="scroll"
                     :globalFilterFields="['titleDescription','dated_at','last_updated_at','theme','domain','subDomain']"
                     @filter="filterDataTable($event)"
                     v-model:selection="selectedDocuments"
                     @row-select="selectDocument($event)"
                     @row-unselect="diselectDocument($event)"
                     @row-select-all="selectAllDocument($event)"
                     @row-unselect-all="unSelectAllDocument()"
                     :scrollable="true" scrollHeight="400px" scrollDirection="both"
                     :row-class="rowClassDocuments"
          >
            <template #empty>
              {{ t('NoDataToDisplay') }}
            </template>
            <template #loading>
              {{ t('LoadingDataPleaseWait') }}
            </template>

            <Column selectionMode="multiple" :style="{width:'30px'}" frozen></Column>


            <Column field="name" :header="t('text')" :style="{width:'300px'}" filterField="titleDescription"
                    :showFilterMatchModes="false" frozen>
              <template #body="{data}">
                <div class="tooltip"
                     v-tooltip.right="'<p><strong> '+  t('text') +' : </strong>'+data.title+'</p><p><strong>Description : </strong>'+
                      data.description+'</p><p><strong>'+t('fieldApplication') +' : </strong>'+data.application_fields+'</p>'">
                  <p>{{ data.title }}</p>
                  <p v-html="data.description"></p>
                </div>


              </template>
              <template #filter="{filterModel}">
                <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                           placeholder="Titre"/>
                <!--                <button>change </button>-->
              </template>
            </Column>
            <Column :header="t('dateOfText')" :style="{width:'150px'}" filterField="dated_at"
                    :showFilterMatchModes="false">
              <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle"
                      class="image-text"> {{ formatDate(data.dated_at) }}</span>
              </template>
              <template #filter="{filterModel}">
                <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy"/>
              </template>
            </Column>
            <Column :header="t('updatedAt')" :style="{width:'150px'}" filterField="last_updated_at"
                    :showFilterMatchModes="false">
              <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle"
                      class="image-text">{{ formatDate(data.last_updated_at) }}</span>
              </template>
              <template #filter="{filterModel}">
                <Calendar v-model="filterModel.value" dateFormat="dd/mm/yy" placeholder="dd/mm/yyyy"/>
              </template>
            </Column>
            <Column :header="t('theme')" filterField="theme" :style="{width:'150px'}"
                    :showFilterMatchModes="false">
              <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle" v-if="data.theme"
                      class="image-text">{{ data.theme.name }}</span>
              </template>
              <template #filter="{filterModel}">
                <Dropdown v-model="filterModel.value" :options="categoriesThemeDoc" placeholder=" Any "
                          class="p-column-filter" :showClear="true">
                  <template #value="slotProps">
                    <span :class="'customer-badge status-' + slotProps.value"
                          v-if="slotProps.value">{{ slotProps.value.label }}</span>
                    <span v-else>{{ t('select') }}</span>
                  </template>
                  <template #option="slotProps">
                        <span :class="'customer-badge status-' + slotProps.option.label">{{
                            slotProps.option.label
                          }}</span>
                  </template>
                </Dropdown>
              </template>
            </Column>
            <Column :header="t('domain')" filterField="domain" :showFilterMatchModes="false"
                    :filterMenuStyle="{'width':'14rem'}" :style="{width:'150px'}">
              <template #body="{data}">
                <span style="margin-left: .5em; vertical-align: middle" v-if="data.domain"
                      class="image-text">{{ data.domain.name }}</span>
              </template>
              <template #filter="{filterModel}">
                <Dropdown v-model="filterModel.value" :options="categoriesDomain" placeholder=" Any "
                          class="p-column-filter" :showClear="true">
                  <template #value="slotProps">
                    <span :class="'customer-badge status-' + slotProps.value"
                          v-if="slotProps.value">{{ slotProps.value.label }}</span>
                    <span v-else>{{ t('select') }}</span>
                  </template>
                  <template #option="slotProps">
                        <span :class="'customer-badge status-' + slotProps.option.label">{{
                            slotProps.option.label
                          }}</span>
                  </template>
                </Dropdown>
              </template>
            </Column>
            <Column :header="t('subDomain')" filterField="subDomain" :style="{width:'150px'}"
                    :showFilterMatchModes="false">
              <template #body="{data}">
                <span v-if="data.sub_domain">{{ data.sub_domain.name }}</span>
              </template>
              <template #filter="{filterModel}">
                <Dropdown v-model="filterModel.value" :options="categoriesSubDomain" placeholder=" Any "
                          class="p-column-filter" :showClear="true">
                  <template #value="slotProps">
                    <span :class="'customer-badge status-' + slotProps.value"
                          v-if="slotProps.value">{{ slotProps.value.label }}</span>
                    <span v-else>{{ t('select') }}</span>
                  </template>
                  <template #option="slotProps">
                        <span :class="'customer-badge status-' + slotProps.option.label">{{
                            slotProps.option.label
                          }}</span>
                  </template>
                </Dropdown>
              </template>
            </Column>

            <Column :header="t('applicability')" :style="{width:'150px'}" frozen alignFrozen="right">
              <template #body="{data}">
                <span>{{ data?.applicability?.label }}</span>
              </template>

            </Column>


          </DataTable>


          <template #footer>
            <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                    @click="hideDialogDocument"/>


            <Button :label="t('confirm')" icon="pi pi-check" class="p-button-text next_btn"
                    @click="saveAssignDocuments()"/>

          </template>
        </Dialog>

        <Dialog v-model:visible="importSiteDialog" :style="{width: '900px'}" :header="t('importSites')"
                :modal="true"
                class="modal_general p-fluid">

          <div class="formgrid grid">
            <div class="field-control col-12">
              <label for="uploadfiles">Fichier d'arborescence XLS :</label>

              <FileUpload mode="basic" accept=".xlsx" ref="file" :maxFileSize="1000000" label="Import"
                          chooseLabel="Import" class="mr-2 inline-block"
                          v-on:change="onUploadSites()"/>

            </div>
          </div>

          <template #footer>
            <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                    @click="hideDialogImportSites"/>


            <Button :label="t('import')" icon="pi pi-check" class="p-button-text next_btn"
                    @click="importDocumentSites()"/>

          </template>

        </Dialog>

        <Dialog v-model:visible="copyBaseDialog" :style="{width: '900px'}" header="Copier base" :modal="true"
                class="modal_general p-fluid">
          <ul class="my-2 list-option-copy">
            <li>
              <Checkbox value="annotation" v-model="optionsCopy"/>
              <label for="chkbox1">Annotation</label>
            </li>
            <li>
              <Checkbox value="comment" v-model="optionsCopy"/>
              <label for="chkbox1">Commentaire</label>
            </li>
            <li>
              <Checkbox value="evaluation_date" v-model="optionsCopy"/>
              <label for="chkbox1">Date évaluation</label>
            </li>
            <li>
              <Checkbox value="historic" v-model="optionsCopy"/>
              <label for="chkbox1">Historique</label>
            </li>

          </ul>

          <TreeTable :value="customersSites" :expandedKeys="expandedKeys" :lazy="true"
                     responsiveLayout="scroll" selectionMode="checkbox"
                     v-model:selectionKeys="selectedCustomers"
                     :loading="loadingCustomersSite"
          >
            <!--            <Column selectionMode="multiple" headerStyle="width: 3em"></Column>-->

            <Column field="id" header="Id" :expander="true"></Column>
            <Column field="code" header="Code"></Column>
            <Column field="name" :header="t('wording')"></Column>

            <!--            <Column :header="t('update')" style="width:10rem;text-align: center;">-->
            <!--              <template #body="slotProps">-->
            <!--                <Button icon="pi pi-pencil" class="button-table mr-2" @click="modalEditSite(slotProps.node)"-->
            <!--                        v-tooltip.top="{value:t('edit'), class: 'editBtn'}"/>-->
            <!--              </template>-->
            <!--            </Column>-->
            <!--            <Column :header="t('copyBase')" style="width:10rem;text-align: center;">-->
            <!--              <template #body="slotProps">-->
            <!--                <Button icon="pi pi-pencil" class="button-table mr-2" @click="modalCopyBaseSite(slotProps.node)"-->
            <!--                        v-tooltip.top="{value:t('edit'), class: 'editBtn'}"-->
            <!--                        v-if="slotProps.node.entity_type == 'site'"/>-->
            <!--              </template>-->
            <!--            </Column>-->
            <template #empty>
              {{ t('NoDataToDisplay') }}
            </template>
          </TreeTable>

          <template #footer>
            <Button :label="t('cancel')" icon="pi pi-times" class="p-button-text event_remove"
                    @click="hideDialogBaseClone"/>

            <Button :label="t('modify')" icon="pi pi-check" class="p-button-text next_btn"
                    @click="CopyBaseClone()"/>


          </template>
        </Dialog>

      </div>
    </div>
    <ConfirmDialog :breakpoints="{'960px': '75vw', '640px': '100vw'}" :style="{width: '50vw'}"></ConfirmDialog>

  </div>
  <Toast/>
</template>

<script>
import {useRoute} from "vue-router";
import {useStore} from "vuex";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import {useToast} from "primevue/usetoast";
import {usePrimeVue} from "primevue/config";
import {FilterMatchMode} from "primevue/api";
import moment from "moment-timezone";
import CustomerService from "@/service/CustomerService";
import {computed} from "vue";

export default {
  name: "AdminSiteMaster",
  data() {
    return {
      idCustomer: '',
      sites: null,
      expandedKeys: {},
      addSiteDialog: false,
      site: {},
      categoriesTheme: [],
      countries: null,
      selectedCountries: [],
      selectedCategoriesTheme: [],
      selectedsiteParent: '',
      customer: '',
      totalItemsSite: 0,
      nbPagesSites: 1,
      sitesParents: [],
      loadingDocuments: false,
      loadingAssignDocuments: false,
      documents: [],
      assignDocuments: [],
      nbElmentsAssignDocuments: 0,
      nbPageAssignDocuments: 1,
      nbPageDocuments: 1,
      filters1: null,
      filtersAssignDocument: null,
      id_site: '',
      categoryValueTheme: null,
      categoriesDomain: [],
      categoriesDomainfilter: [],
      categoryValueDomain: null,
      categoriesSubDomain: [],
      categoriesSubDomainFilter: [],
      categoryValueSubDomain: null,
      categoriesThemeDoc: [],
      categoriesThemeDocFilter: [],
      valuecountry: '',
      typeValue: '',
      typeValues: [
        {id: 1, name: 'Entity', code: 'entity'},
        {id: 2, name: 'Site', code: 'site'}
      ],
      submitted: false,
      isSite: true,
      assignDocumentsDialog: false,
      valueStatusAffect: {id: 0, label: 'Pour information', is_applicable: false},
      valuesStatusAffect: [
        {id: 0, label: 'Pour information', is_applicable: false},
        {id: 1, label: 'Avec exigences', is_applicable: true},
      ],
      selectedDocuments: [],
      nbDocumentsSelected: 0,
      customer1: null,
      loading1: true,
      customerService: null,
      importSiteDialog: false,
      importFiles: null,
      fileImportSites: '',
      copyBaseDialog: false,
      customersSites: [],
      selectedCustomers: [],
      loadingCustomersSite: false,
      optionsCopy: [],
      selectedDocument: [],
      loadingSites: false,
      applicabilities: ['Pour information', 'Avec exigences'],
      categoriesThemeDocAssFilter: [],
      listThemeAffect: [],
      listDomainAffect: [],
      categoriesDomainAssfilter: [],
      listSubDomainAffect: [],
      categoriesSubDomainAssFilter: [],
      filterThemeAssValue: '',
      filterDomainAssValue: '',
      categoriesThemeDocAssFilterConst: [],
      categoriesDomainAssfilterConst: [],
      categoriesSubDomainAssFilterConst: [],
    }
  },
  setup() {
    const primevue = usePrimeVue();
    const store = useStore();
    const route = useRoute();
    const toast = useToast();
    const user = computed(() => {
      return store.getters.currentUser
    });
    let user_id = user.value?.id;
    const current_site = computed(() => {
      return store.getters.currentSite
    });
    return {store, route, toast, primevue, t: primevue.config.StoreTranslation.t, user_id, current_site}
  },
  mounted() {
    this.customerService.getCustomersLarge().then(data => {
      this.customer1 = data;
      this.loading1 = false;
      this.customer1.forEach(customer => customer.date = new Date(customer.date));
    });
    this.getSitesByCustomerParents()
  },
  beforeMount() {
    this.idCustomer = this.route.params.idCustomer;
    this.store.dispatch(Actions.GET_CUSTOMERS_BY_ID, {id: this.idCustomer, 'customHeader': false}).then(data => {
      this.customer = data
    })
    this.getSitesByCustomer(1)
    this.getCategoriesByThemeDoc()
  },
  created() {
    this.customerService = new CustomerService();
    this.initFilters1();
  },
  methods: {
    initFilters1() {
      this.filters1 = {
        'titleDescription': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'dated_at': {value: null, matchMode: FilterMatchMode.DATE_IS},
        'last_updated_at': {value: null, matchMode: FilterMatchMode.DATE_IS},
        'theme': {value: null, matchMode: FilterMatchMode.EQUALS},
        'domain': {value: null, matchMode: FilterMatchMode.EQUALS},
        'subDomain': {value: null, matchMode: FilterMatchMode.EQUALS},
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'country.name': {value: null, matchMode: FilterMatchMode.STARTS_WITH},
      }
      this.filtersAssignDocument = {
        'title': {value: null, matchMode: FilterMatchMode.CONTAINS},
        'dated_at': {value: null, matchMode: FilterMatchMode.DATE_IS},
        'last_updated_at': {value: null, matchMode: FilterMatchMode.DATE_IS},
        'theme': {value: null, matchMode: FilterMatchMode.EQUALS},
        'domain_h': {value: null, matchMode: FilterMatchMode.EQUALS},
        'sub_domain_h': {value: null, matchMode: FilterMatchMode.EQUALS},
        'is_applicable': {value: null, matchMode: FilterMatchMode.EQUALS},
      }
    },
    clearFilter1() {
      this.initFilters1();
    },
    getSitesByCustomer(page = 1) {
      this.loadingSites = true
      this.store.dispatch(Actions.GET_CUSTOMERS_SITE_TREE, {
        'customer': this.idCustomer,
        'page': page,
        'customHeader': true
      }).then(data => {
        let sites = data['hydra:member'][0]?.childrens
        var formatSelectTree = (obj, indexCh = null) => {
          obj?.forEach((element, index) => {
            let indexF = '0';
            if (null === indexCh) {
              indexF = index + '';
            } else {
              indexF = indexCh + '-' + index
            }
            if (Object.prototype.hasOwnProperty.call(element, 'childrens')) {
              formatSelectTree(element.childrens, indexF)
            }
            Object.assign(element, {"children": element.childrens})
            if (Object.prototype.hasOwnProperty.call(element, 'site_name')) {
              Object.assign(element, {"label": element.site_name})
              element.id = element.site_id
              element.name = element.site_name
            } else {
              Object.assign(element, {"label": element.name})
            }
            Object.assign(element, {"icon": 'pi pi-fw pi-inbox'})
            Object.assign(element, {"key": element.id})
            Object.assign(element, {
              "data": {
                "id": element.id,
                "name": element.name,
                "type": element.type,
                "code": element.code,
              }
            })
          });
          return obj;
        };
        this.loadingSites = false
        this.sites = formatSelectTree(sites)
        this.totalItemsSite = data['hydra:totalItems']
      })
    },
    getSitesByCustomerParents(page = 1) {
      this.store.dispatch(Actions.GET_CUSTOMERS_SITE_TREE, {
        'customer': this.idCustomer,
        'page': page,
        'customHeader': true
      }).then(data => {

        let parentSites = data['hydra:member'][0]?.childrens
        if (parentSites) {
          var formatSelectTreeSites = (obj, indexCh = null) => {
            let elemSites = {}
            obj.forEach((element, index) => {
              if (element.entity_type != 'site') {

                let indexF = '0';
                if (null === indexCh) {
                  indexF = index + '';
                } else {
                  indexF = indexCh + '-' + index
                }
                if (Object.prototype.hasOwnProperty.call(element, 'childrens')) {
                  formatSelectTreeSites(element.childrens, indexF)
                  elemSites = element
                }

                if (Object.prototype.hasOwnProperty.call(element, 'childrens') && element.childrens.length > 0) {
                  Object.assign(element, {"children": element.childrens})
                }


                if (Object.prototype.hasOwnProperty.call(element, 'site_name')) {
                  Object.assign(element, {"label": element.site_name})
                  element.id = element.site_id
                  element.name = element.site_name

                } else {
                  Object.assign(element, {"label": element.name})
                }

                Object.assign(element, {"icon": 'pi pi-fw pi-inbox'})

                Object.assign(element, {"key": element.id})
                Object.assign(element, {
                  "data": {
                    "id": element.id,
                    "name": element.name,
                    "type": element.type,
                    "code": element.code,
                  }
                })
              } else {
                // element['children'] = []

                delete elemSites['children']
              }
            });
            return obj;
          };

          var filterSites = (sites) => {
            sites.forEach((row, index) => {
              if (Object.prototype.hasOwnProperty.call(row, 'childrens')) {
                sites[index].childrens = filterSites(row.childrens);
                if (!sites[index].childrens.length) {
                  delete sites[index].childrens;
                }
              }
              if (row.entity_type == 'site') {
                delete sites[index];
              }

            });
            return sites.filter(() => true);
          };
          let baseSites = JSON.parse(JSON.stringify(parentSites));
          let filteredSites = filterSites(baseSites);

          this.sitesParents = formatSelectTreeSites(filteredSites)
        } else
          this.sitesParents = []

      })

    },

    expandAll() {
      for (let site of this.sites) {
        this.expandSite(site);
      }

      this.expandedKeys = {...this.expandedKeys};
    },
    collapseAll() {
      this.expandedKeys = {};
    },
    expandSite(site) {
      if (site.children && site.children.length) {
        this.expandedKeys[site.key] = true;

        for (let child of site.children) {
          this.expandSite(child);
        }
      }
    },
    modalEditSite(dataSite) {
      this.id_site = dataSite.id
      this.submitted = false;
      this.addSiteDialog = true;
      let customHeader = false
      this.store.dispatch(Actions.GET_SITES_BY_ID, {id: dataSite.id, customHeader: customHeader}).then(data => {
        this.site.id = dataSite.id
        this.site.code = data.code;
        this.site.name = data.name;
        this.site.address = data.address;
        this.site.additional_address = data.additional_address;
        this.site.zipcode = data.zipcode;
        this.site.address = data.address; /// ville
        this.site.address = data.address; // pays
        this.site.phone = data.phone;
        this.site.fax = data.fax;
        this.site.naf = data.naf;
        this.site.groupe = data.groupe;
        this.site.adr = data.adr;
        this.site.coordinates_lambert = data.coordinates_lambert;
        this.selectedCountries = data.document_countries
        this.selectedCategoriesTheme = data.document_themes
        this.site.city = data.city
        this.site.year = data.year

        if (data.type == 'entity') {
          this.typeValue = {id: 1, name: 'Entity', code: 'entity'}
          this.isSite = false;
        } else {
          this.typeValue = {id: 2, name: 'Site', code: 'site'}
          this.isSite = true;
        }
        // valuecountry

      })

      this.getCountries();
      this.getCategoriesByTheme();
      this.getDocumentsBySite(this.nbPageAssignDocuments, this.id_site)
    },
    editSite() {

      this.submitted = true;
      let countriesSelected = []
      let documentsThemeSelected = []
      let id_customer = '/api/customers/' + this.idCustomer

      // let id_parent ='/api/sites/'+Object.keys(this.selectedsiteParent)[0] ;

      this.selectedCountries.forEach(element => {
        countriesSelected.push('/api/countries/' + element.id)
      })
      this.selectedCategoriesTheme.forEach(element => {
        documentsThemeSelected.push('/api/categories/' + element.id)
      })
      this.site.country_id = '/api/countries/' + this.valuecountry.id
      // Object.assign(this.site, {"customer_id":id_customer,'document_countries': countriesSelected, 'document_themes': documentsThemeSelected, 'parent': id_parent})
      Object.assign(this.site, {
        "customer_id": id_customer,
        'document_countries': countriesSelected,
        'document_themes': documentsThemeSelected
      })
      if (Object.keys(this.selectedsiteParent).length > 0)
        this.site.parent = '/api/sites/' + Object.keys(this.selectedsiteParent)
      this.site.type = this.typeValue.code
      if (this.site.code && this.site.name) {
        this.store.dispatch(Actions.PUT_SITE, {
          'site': this.site,
          'idSite': this.site.id
        }, this.site.id).then(() => {
          this.addSiteDialog = false;
          this.toast.add({
            severity: 'success',
            detail: this.t('siteHasSuccessfullyModified'),
            life: 3000
          });
          this.getSitesByCustomer(this.nbPagesSites)
        })
      }
    },

    openNewSite() {
      this.submitted = false;
      this.addSiteDialog = true;
      this.site = {}
      this.getCountries();
      this.getCategoriesByTheme();

    },
    saveSite() {
      this.submitted = true
      let countriesSelected = []
      let documentsThemeSelected = []
      let id_customer = '/api/customers/' + this.idCustomer
      if (Object.keys(this.selectedsiteParent)[0]) {
        this.site.parent = '/api/sites/' + Object.keys(this.selectedsiteParent)[0]
      }
      this.site.country_id = '/api/countries/' + this.valuecountry.id
      this.selectedCountries.forEach(element => {
        countriesSelected.push('/api/countries/' + element.id)
      })
      this.selectedCategoriesTheme.forEach(element => {
        documentsThemeSelected.push('/api/categories/' + element.id)
      })

      Object.assign(this.site, {
        "customer": id_customer,
        'document_countries': countriesSelected,
        'document_themes': documentsThemeSelected
      })
      this.site.type = this.typeValue.code
      if (this.site.code && this.site.name) {
        this.store.dispatch(Actions.ADD_SITE, this.site).then(() => {
          this.toast.add({
            severity: 'success',
            detail: this.t('siteHasSuccessfullyAdded'),
            life: 3000
          });
          this.addSiteDialog = false;
          this.getSitesByCustomer(this.nbPagesSites)
        })
      }
    },
    getCountries() {
      this.countries = []
      this.store.dispatch(Actions.GET_COUNTRIES, {'customHeader': false,}).then(data => {
        this.countries = data
      })
    },
    getCategoriesByTheme() {
      let tabCategoriesTheme = []

      this.store.dispatch(Actions.GET_CATEGORIES, {type: 'THEME', 'customHeader': false,}).then(data => {
        data.forEach(function (element) {
          if (element.type === 'THEME') {
            tabCategoriesTheme.push(element)
          }
        });
        this.categoriesTheme = tabCategoriesTheme;
      })
    },
    hideDialog() {
      this.addSiteDialog = false;
    },
    onPageSite(event) {
      this.nbPagesSites = event.page + 1
      this.getSitesByCustomer(event.page + 1);
    },
    backToCustomer() {
      this.$router.push('/ui-ax/admin-customer');
    },


    getDocumentsBySite(page = 1, id_site) {
      console.log(page)
      this.loadingAssignDocuments = true
      // this.assignDocuments = []
      // this.loading[index] = true;
      let idTheme
      let idDomain
      let idSubDomain
      if (this.categoryValueTheme) {
        idTheme = this.categoryValueTheme.id
      }
      if (this.categoryValueDomain) {

        idDomain = this.categoryValueDomain.id
      }
      if (this.categoryValueSubDomain) {
        idSubDomain = this.categoryValueSubDomain.id
      }

      this.store.dispatch(Actions.AFFECTATION_DOCUMENTS, {
        'idSite': id_site,
        'theme': idTheme,
        'domain': idDomain,
        'sub_domain': idSubDomain,
        'text': this.textAdmin,
        'dated_at': this.dated_at_format,
        'last_updated_at': this.last_updated_at_format,
        'customHeader': false,
        // page: page,
      }).then(data => {
        this.assignDocuments = data
        if (this.assignDocuments) {
          this.assignDocuments.forEach(element => {

            element.title = element.document.title
            // if (element.document.theme) {
            //   element.theme = element.document.theme.name
            // }

            if (element.document.theme) {
              let theme_object = element.document.theme
              element.theme = theme_object.name
              if (!this.listThemeAffect.find(item => item.id === theme_object.id)) {
                this.listThemeAffect.push(theme_object)
                this.categoriesThemeDocAssFilter.push(theme_object.name)
              }
            }


            if (element.document.domain) {
              let domain_object = element.document.domain
              let newObjectDomain = {...domain_object}
              element.domain = domain_object.name
              newObjectDomain.name += ' ( ' + newObjectDomain.parent?.name + ' ) '
              element['domain_h'] = newObjectDomain.name
              if (!this.listDomainAffect.find(item => item.id === domain_object.id)) {
                this.listDomainAffect.push(domain_object)

                this.categoriesDomainAssfilter.push(newObjectDomain.name)
              }
            }

            if (element.document.sub_domain) {
              let sub_domain_object = element.document.sub_domain
              let newObjectSubDomain = {...sub_domain_object}
              element.subDomain = sub_domain_object.name
              newObjectSubDomain.name += ' ( ' + newObjectSubDomain.parent?.name + ' ) '
              element['sub_domain_h'] = newObjectSubDomain.name
              if (!this.listSubDomainAffect.find(item => item.id === sub_domain_object.id)) {
                this.listSubDomainAffect.push(sub_domain_object)

                this.categoriesSubDomainAssFilter.push(newObjectSubDomain.name)
              }
            }
            element.dated_at = new Date(element.document.dated_at)
            element.last_updated_at = new Date(element.document.last_updated_at)
            element.document_id = element.document?.id

            element.is_applicable = element.is_applicable ? 'Avec exigences' : 'Pour information'
          })
          this.categoriesThemeDocAssFilter.sort((a, b) => a.localeCompare(b));
          this.categoriesThemeDocAssFilterConst = this.categoriesThemeDocAssFilter
          this.categoriesDomainAssfilter.sort((a, b) => a.localeCompare(b));
          this.categoriesDomainAssfilterConst = this.categoriesDomainAssfilter
          this.categoriesSubDomainAssFilter.sort((a, b) => a.localeCompare(b));
          this.categoriesSubDomainAssFilterConst = this.categoriesSubDomainAssFilter
          this.nbElmentsAssignDocuments = this.assignDocuments.length
        }
        this.loadingAssignDocuments = false

      })
      // setTimeout(() => this.loading[index] = false, 1000);
    },

    onPageAssignDocument(event) {
      this.nbPageAssignDocuments = event.page + 1
      this.getDocumentsBySite(this.nbPageAssignDocuments, this.id_site);
    },
    formatDate(value) {
      if (value){
        return new Date(value).toLocaleDateString('fr-FR', {
          day: '2-digit',
          month: '2-digit',
          year: 'numeric',
        });
      }
    },

    filterDataTable(event) {

      this.textAdmin = event.filters.titleDescription.value

      if (event.filters.dated_at.value) {
        this.dated_at_format = moment(event.filters.dated_at.value).format()
      }
      if (event.filters.last_updated_at.value) {
        this.last_updated_at_format = moment(event.filters.last_updated_at.value).format()
      }
      this.categoryValueTheme = event.filters.theme.value

      if (event.filters.theme.value) {
        this.changeTheme()
      } else if (!event.filters.domain.value) {
        this.getCategoriesByThemeDoc()
      }

      if (this.categoriesDomain.length > 0) {
        if (event.filters.domain.value != this.categoryValueDomain) {
          this.categoryValueDomain = event.filters.domain.value
          this.changeDomain()
          if ((this.categoryValueSubDomain == 2 && !event.filters.subDomain.value) || (this.categoryValueSubDomain == 2 && event.filters.subDomain.value)) {
            event.filters.subDomain.value = null
          }
        }

        this.categoryValueSubDomain = event.filters.subDomain.value
      } else {
        event.filters.domain.value = null
        event.filters.subDomain.value = null
        this.categoryValueDomain = ''
        this.categoryValueSubDomain = null

      }
      this.getDocumentsBySite(this.nbPageAssignDocuments, this.id_site);
      this.loadDocuments(this.nbPageDocuments)

    },


    getCategoriesByThemeDoc() {
      let tabCategoriesTheme = []
      let tabCategoriesDomain = []
      let tabCategoriesSubDomain = []

      this.store.dispatch(Actions.GET_CATEGORIES_TREE, {'customHeader': false,}).then(data => {


        var formatSelectTree = (obj, indexCh = null) => {
          obj.forEach((element, index) => {
            let indexF = '0';
            if (null === indexCh) {
              indexF = index + '';
            } else {
              indexF = indexCh + '-' + index
            }

            if (Object.prototype.hasOwnProperty.call(element, 'categories')) {
              formatSelectTree(element.categories, indexF)

              if (element.type == 'THEME') {
                tabCategoriesTheme.push(element)
              } else if (element.type == 'DOMAIN') {
                tabCategoriesDomain.push(element)
              }
              // else {
              //   tabCategoriesSubDomain.push(element)
              // }
            } else {
              if (element.type == 'SUB_DOMAIN') {
                tabCategoriesSubDomain.push(element)
              }
            }

            Object.assign(element, {"children": element.categories})
            //  Object.assign(element, {"label":element.name})
            Object.assign(element, {"icon": 'pi pi-fw pi-inbox'})
            Object.assign(element, {"label": element.name})

            Object.assign(element, {"key": element.id})
            Object.assign(element, {
              "data": {
                "id": element.id,
                "name": element.name,
                "type": element.type,
                "code": element.code,
              }
            })

          });
          return obj;
        };
        this.tabCategories = formatSelectTree(data)

        this.categoriesThemeDoc = tabCategoriesTheme;
        this.categoriesThemeDoc.forEach(element => {
          this.categoriesThemeDocFilter.push(element.label)
        })
        this.categoriesDomain = tabCategoriesDomain;
        this.categoriesDomain.forEach(element => {
          this.categoriesDomainfilter.push(element.label)
        })
        this.categoriesSubDomain = tabCategoriesSubDomain;
        this.categoriesSubDomain.forEach(element => {
          this.categoriesSubDomainFilter.push(element.label)
        })

        this.valuesSubDomain = tabCategoriesSubDomain;
      })
    },
    changeTheme() {
      let tabCategoriesDomain = []
      let tabCategoriesSubDomain = []

      let objTheme = this.categoriesThemeDoc.find(o => o.id == this.categoryValueTheme.id);
      objTheme.categories.forEach(function (element) {
        if (element.type === 'DOMAIN') {
          tabCategoriesDomain.push(element)
        }
        if (element.type === 'SUB_DOMAIN') {
          tabCategoriesSubDomain.push(element)
        }
      })
      this.categoriesDomain = tabCategoriesDomain;
      this.categoriesSubDomain = tabCategoriesSubDomain;

    },
    changeDomain() {
      let tabCategoriesSubDomain = []

      if (this.categoryValueDomain) {
        let objDomain = this.categoriesDomain.find(o => o.id == this.categoryValueDomain.id);
        objDomain.categories.forEach(function (element) {

          if (element.type === 'SUB_DOMAIN') {
            tabCategoriesSubDomain.push(element)
          }
        })
        this.categoryValueSubDomain = 2
        this.categoriesSubDomain = tabCategoriesSubDomain;
      }
    },

    assignDocumentsModal() {
      this.assignDocumentsDialog = true;
      if (this.documents['hydra:member']) {
        this.documents['hydra:member'].find((element) => {
          this.selectedDocuments.filter(item => item.id != element.id)
        })
        this.selectedDocuments = []
        this.nbDocumentsSelected = this.selectedDocuments.length
      }
      this.loadDocuments(1)
    },

    loadDocuments(page = 1) {
      this.loadingDocuments = true
      this.documents = []
      // this.loading[index] = true;
      let idTheme = this.idThemeR
      let idDomain = this.idDomainR
      let idSubDomain = this.idSubDomainR
      if (this.categoryValueTheme) {
        idTheme = this.categoryValueTheme.id
      } else {
        let objTheme = this.categoriesTheme.find(o => o.id == idTheme);
        this.categoryValueTheme = objTheme
      }
      if (this.categoryValueDomain) {

        idDomain = this.categoryValueDomain.id
      } else {
        let objDomain = this.categoriesDomain.find(o => o.id == idDomain);
        this.categoryValueDomain = objDomain
      }
      if (this.categoryValueSubDomain) {
        idSubDomain = this.categoryValueSubDomain.id
      } else {
        let objSubDomain = this.categoriesSubDomain.find(o => o.id == idSubDomain);
        this.categoryValueSubDomain = objSubDomain
      }

      if (this.dated_at_format == '') {
        if (this.dated_atR != '') {
          this.dated_at_format = this.dated_atR
        }
      }
      if (this.last_updated_at_format == '') {
        if (this.last_updated_atR != '') {
          this.last_updated_at_format = this.last_updated_atR
        }
      }


      return this.store.dispatch(Actions.GET_DOCUMENTS, {
        'theme': idTheme,
        'domain': idDomain,
        'sub_domain': idSubDomain,
        'text': this.textAdmin,
        'dated_at': this.dated_at_format,
        'last_updated_at': this.last_updated_at_format,
        'customHeader': true,
        'limit': 30,
        page: page,
      }).then(data => {
        // this.store.commit(Mutations.SET_ID_THEME,
        //     {
        //       idTheme: idTheme, idDomain: idDomain, idSubDomain: idSubDomain, text: this.textAdmin, dated_at: this.dated_at_format, last_updated_at: this.last_updated_at_format, search: true
        //     }
        // );

        // toolService.saveTheme(idTheme)
        this.documents = data
        // this.loading[index] = false;
        this.loadingDocuments = false

        if (this.assignDocuments) {
          this.assignDocuments.forEach(element => {
            this.documents['hydra:member'] = this.documents['hydra:member'].filter(item => item.id != element.document.id)
          })
        }


        this.selectedDocuments.forEach(element => {
          let objDocument = this.documents['hydra:member'].find(item => item.id == element.id)
          if (objDocument) {
            objDocument['applicability'] = element.applicability
          }
        })


        // this.documents['hydra:member'].find((element, index) => {
        //   if (event.data.id == element.id) {
        //     this.documents['hydra:member'][index]['applicability'] = this.valueStatusAffect
        //   }
        // })

      })
      // setTimeout(() => this.loading[index] = false, 1000);
    },

    onPageDocuments(event) {

      this.nbPageDocuments = event.page + 1
      this.loadDocuments(this.nbPageDocuments);
    },

    selectDocument(event) {
      this.documents['hydra:member'].find((element, index) => {
        if (event.data.id == element.id) {
          this.documents['hydra:member'][index]['applicability'] = this.valueStatusAffect
        }
      })

      this.nbDocumentsSelected = this.selectedDocuments.length
      // objDocument['applicability'] = this.valueStatusAffect;

    },

    selectAllDocument(event) {


      event.data.forEach(element => {
        this.selectedDocuments.push(element)
      })


      this.selectedDocuments.forEach(element => {
        let objDocument = this.documents['hydra:member'].find(item => item.id == element.id)
        if (objDocument) {
          objDocument.applicability = this.valueStatusAffect
        }
      })

      this.nbDocumentsSelected = this.selectedDocuments.length


    },
    unSelectAllDocument() {

      this.documents['hydra:member'].find((element, index) => {
        this.documents['hydra:member'][index]['applicability'] = ''

      })

      this.selectedDocuments = []
      this.nbDocumentsSelected = this.selectedDocuments.length

    },
    diselectDocument(event) {
      this.documents['hydra:member'].find((element, index) => {
        if (event.data.id == element.id) {
          this.documents['hydra:member'][index]['applicability'] = ''
        }
      })
      // objDocument['applicability'] = this.valueStatusAffect;
      this.nbDocumentsSelected = this.selectedDocuments.length
    },
    hideDialogDocument() {
      this.assignDocumentsDialog = false
    },

    saveAssignDocuments() {
      let assignDocuments = [];
      this.selectedDocuments.forEach(element => {
        let objAssign = {'doc_id': element.id, 'is_applicable': element.applicability.is_applicable}
        assignDocuments.push(objAssign)
      })
      this.store.dispatch(Actions.PUT_SITE, {
        'site': {'docsIds': assignDocuments, 'idUser': this.user_id},
        'idSite': this.site.id,
        'customHeader': false
      }).then(() => {
        this.assignDocumentsDialog = false
        this.getDocumentsBySite(this.nbPageAssignDocuments, this.id_site);
        this.selectedDocuments = []
        this.nbDocumentsSelected = 0
      })
    },
    importSites() {
      this.importSiteDialog = true
    },
    onUploadSites() {
      this.fileImportSites = this.$refs.file.files[0]

    },
    importDocumentSites() {


      this.store.dispatch(Actions.IMPORT_FILE, {
        file: this.fileImportSites,
        model: 'importSiteCustomer',
        params: {'customer_id': this.idCustomer}

      }).then(() => {
        this.toast.add({
          severity: 'success',
          detail: this.t('importWasSuccessfullySent'),
          life: 3000
        });
        this.importSiteDialog = false
      })
    },
    hideDialogImportSites() {
      this.importSiteDialog = false

    },
    rowClassDocuments(rowData) {
      return rowData.applicability?.is_applicable ? "row-compliance" : "row-information";

    },
    modalCopyBaseSite(data) {
      this.selectedCustomers = []
      this.optionsCopy = []
      this.id_site = data.id
      this.copyBaseDialog = true
      this.getTreeCustomersSites()
    },
    getTreeCustomersSites() {
      this.customersSites = []
      this.loadingCustomersSite = true;
      this.store.dispatch(Actions.GET_CUSTOMERS_SITE_TREE, {
        'customHeader': false
      }).then(data => {
        this.loadingCustomersSite = false;

        let customersSites = data

        var formatSelectTree = (obj, indexCh = null) => {
          obj.forEach((element, index) => {
            let indexF = '0';
            if (null === indexCh) {
              indexF = index + '';
            } else {
              indexF = indexCh + '-' + index
            }
            if (Object.prototype.hasOwnProperty.call(element, 'childrens')) {
              formatSelectTree(element.childrens, indexF)

            }
            Object.assign(element, {"children": element.childrens})


            if (Object.prototype.hasOwnProperty.call(element, 'site_name')) {
              Object.assign(element, {"label": element.site_name})
              element.id = element.site_id
              element.name = element.site_name

            } else {
              Object.assign(element, {"label": element.name})
            }
            Object.assign(element, {"icon": 'pi pi-fw pi-inbox'})

            Object.assign(element, {"key": element.id})
            Object.assign(element, {
              "data": {
                "id": element.id,
                "name": element.name,
                "type": element.type,
                "code": element.code,
              }
            })

          });
          return obj;
        };


        this.customersSites = formatSelectTree(customersSites)


        this.totalItemsSite = data['hydra:totalItems']


      })
    },

    CopyBaseClone() {
      let selectedSites = []

      Object.entries(this.selectedCustomers).forEach(element => {
        if (element[1].checked) {
          selectedSites.push(element[0])
        }
      })

      let exitHistory = false
      if (this.optionsCopy.find(item => item == "historic")) {
        exitHistory = true
      }
      let exitAnnotation = false
      if (this.optionsCopy.find(item => item == "annotation")) {
        exitAnnotation = true
      }
      let exitComment = false
      if (this.optionsCopy.find(item => item == "comment")) {
        exitComment = true
      }

      let exitEvaluationDate = false
      if (this.optionsCopy.find(item => item == "evaluation_date")) {
        exitEvaluationDate = true
      }


      if (selectedSites.length > 0) {
        this.store.dispatch(Actions.ADD_SITES_CLONE, {
          "site_id_1": this.id_site,
          "site_id_2": selectedSites[0],
          "history": exitHistory,
          "annotation": exitAnnotation,
          "comment": exitComment,
          "evaluation_date": exitEvaluationDate,
        }).then(() => {
          this.copyBaseDialog = false
        })
      }
    },
    hideDialogBaseClone() {
      this.copyBaseDialog = false

    },
    desaffectDocument() {
      let selectedDocument = this.selectedDocument.map(item => item.id)
      if (selectedDocument.length > 0) {
        this.loadingAssignDocuments = true
        this.store.dispatch(Actions.DESAFFECT_DOCUMENTS, {ids: selectedDocument}).then(() => {
          this.getDocumentsBySite(this.nbPageAssignDocuments, this.id_site);
          this.toast.add({
            severity: 'success',
            detail: this.t('selectionDesatributeSuccess'),
            life: 3000
          });
          this.selectedDocument = []
        })
      } else {
        this.toast.add({
          severity: 'warn',
          detail: this.t('aucunSelectDocument'),
          life: 3000
        });
      }
    },
    deleteSite(id_site) {
      this.$confirm.require({
        message: this.t('AreYouSureYouWantToDelete'),
        header: 'Confirmation',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
          this.store.dispatch(Actions.DELETE_SITE, {
            id_site: id_site,
            current_site: this.current_site?.id
          }).then((data) => {

            if (id_site == this.current_site.id && data == 'init_site') {
              this.store.commit(Mutations.SET_SITE, {
                id: null,
                name: null,
                type: null
              });
            }
            this.getSitesByCustomer(1)
            this.toast.add({
              severity: 'success',
              detail: this.t('siteHasSuccessfullyDeleted'),
              life: 8000
            });

          })
        },
        reject: () => {
        },
        onHide: () => {
        }
      });
    },
    filterDataTableAssignDocuments(event){

      this.categoriesThemeDocAssFilter = []
      this.categoriesDomainAssfilter = []
      this.categoriesSubDomainAssFilter = []
      this.applicabilities = []
      event.filteredValue?.forEach(element => {
        if (!this.categoriesThemeDocAssFilter.find(item => item === element.theme)){
          this.categoriesThemeDocAssFilter.push(element.theme)
        }
        if (!this.categoriesDomainAssfilter.find(item => item === element.domain_h)){
          this.categoriesDomainAssfilter.push(element.domain_h)
        }
        if (!this.categoriesSubDomainAssFilter.find(item => item === element.sub_domain_h)){
          this.categoriesSubDomainAssFilter.push(element.sub_domain_h)
        }
        if (!this.applicabilities.find(item => item === element.is_applicable)){
          this.applicabilities.push(element.is_applicable)
        }

      })

    },
    changeThemeAss() {
      let tabCategoriesDomain = []
      let tabCategoriesSubDomain = []

        let filter_theme_obj = this.listThemeAffect.find(item => item.name == this.filterThemeAssValue)
        let filter_theme_id = filter_theme_obj?.id
        if (filter_theme_id){
          tabCategoriesDomain = this.listDomainAffect.filter(item => item.parent?.id == filter_theme_id).map(item => item.name + ' ( ' + item.parent?.name + ' ) ')
          tabCategoriesSubDomain = this.listSubDomainAffect.filter(item => item.parent?.parent?.id == filter_theme_id).map(item => item.name + ' ( ' + item.parent?.name + ' ) ')
        }

        this.categoriesDomainAssfilter = tabCategoriesDomain
        this.categoriesSubDomainAssFilter = tabCategoriesSubDomain
        this.categoriesDomainAssfilter.sort((a, b) => a.localeCompare(b));
        this.categoriesSubDomainAssFilter.sort((a, b) => a.localeCompare(b));



    },

    changeDomainAss() {
      let tabCategoriesSubDomain = []
      if(this.filterDomainAssValue){
        const match_theme = this.filterDomainAssValue.match(/\(\s+([^\s)]+)\s+\)/);
        let theme = match_theme?  match_theme[1].trim() : null;

        const match_domain = this.filterDomainAssValue.match(/^(.*?)(?=\(\s+[^\s)]+\s+\))/);
        let domain = match_domain?  match_domain[1].trim() : match_domain;

        let filter_domain_obj = []
        if (theme && this.filterThemeAssValue){
          let filter_theme_obj = this.listThemeAffect.find(item => item.name == theme)
          let filter_theme_id = filter_theme_obj?.id

          filter_domain_obj = this.listDomainAffect.filter(item => item.parent?.id == filter_theme_id && item.name == domain)
          if (filter_domain_obj.length > 0 ){
            tabCategoriesSubDomain = this.listSubDomainAffect.filter(item => item.parent?.id == filter_domain_obj[0]?.id).map(item => item.name + ' ( ' + item.parent?.name + ' ) ')

            this.categoriesSubDomainAssFilter = tabCategoriesSubDomain
            this.categoriesSubDomainAssFilter.sort((a, b) => a.localeCompare(b));
          }

        }
        else if (!this.filterThemeValue){
          this.categoriesDomainAssfilter = this.categoriesDomainAssfilterConst
          tabCategoriesSubDomain = this.listSubDomainAffect.filter(item => item.parent?.name === domain).map(item => item.name + ' ( ' + item.parent?.name + ' ) ')

          this.categoriesSubDomainAssFilter = tabCategoriesSubDomain
          this.categoriesSubDomainAssFilter.sort((a, b) => a.localeCompare(b));
        }
      }
    },

    changeSubDomain(){
      this.categoriesSubDomainAssFilter = this.categoriesSubDomainAssFilterConst
    },
  }

}
</script>

<style lang="scss" scoped>


.flex_btn_attribute button .p-button-label {
  flex: inherit;
}

::v-deep(.p-datatable-frozen-tbody) {
  font-weight: bold;
}

::v-deep(.p-datatable-scrollable .p-frozen-column) {
  font-weight: bold;
}


</style>